import React from 'react'
import Layout from '../components/Layout'
const io = require('socket.io-client')

class Aoe2 extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      ready: false,
      users: [],
      groups: []
    }

    this.socket = null

    this.handleChangeName = this.handleChangeName.bind(this)
    this.onConfirmName = this.onConfirmName.bind(this)
    this.handleGenerateGroup = this.handleGenerateGroup.bind(this)
  }

  componentDidMount () {
    this.socket = io.connect('https://api.rnedvd.com')
    this.socket.emit('changeStatus', { name: this.state.name, ready: this.state.ready })
    this.socket.on('updateUser', (users) => { this.setState({ users }) })
    this.socket.on('updateGroup', (groups) => { this.setState({ groups }) })
  }

  onConfirmName () {
    this.socket.emit('changeStatus', { name: this.state.name, ready: this.state.ready })
  }

  handleChangeName (evt) {
    this.setState({ name: evt.target.value, ready: true })
  }

  handleGenerateGroup () {
    this.socket.emit('generateGroup')
  }

  render () {
    return (
      <Layout location={this.props.location} title='AOE 2'>
        <div className='max-w-sm m-auto'>
          <h3 className='text-2xl text-red-500'>Insert your name</h3>
          <form className='w-full max-w-sm'>
            <div className='flex items-center border-b border-b-2 border-red-500 py-2'>
              <input onChange={this.handleChangeName} value={this.state.name} className='appearance-none bg-transparent border-none w-full text-gray-300 mr-3 py-1 px-2 leading-tight focus:outline-none' type='text' placeholder='Write your name' aria-label='Full name' />
              <button onClick={this.onConfirmName} className='flex-shrink-0 bg-green-500 hover:bg-green-700 border-green-500 hover:border-green-700 text-sm border-4 text-white py-1 px-2 rounded' type='button'>
                Ready
              </button>
            </div>
          </form>
          <h3 className='text-2xl text-red-500'>Partecipands</h3>
          <ul className='my-6'>
            {
              this.state.users.map((el, i) => <li key={`users_${i}`}>{el.name === '' ? 'Unknown' : el.name } {el.ready ? <strong className='float-right text-green-500'>{'\u2713'}</strong> : <strong className='float-right text-red-500'>{'\u2718'}</strong>}</li>)
            }
          </ul>
          {
            this.state.users.length > 1 && !this.state.users.find((el) => !el.ready) && (
              <button onClick={this.handleGenerateGroup} className='flex-shrink-0 bg-green-500 hover:bg-green-700 border-green-500 hover:border-green-700 text-sm border-4 text-white py-1 px-2 rounded' type='button'>
                Generate Groups
              </button>
            )
          }
          {
            this.state.groups && this.state.groups.length > 0 && (
              <div>
                <h3 className='text-2xl text-red-500 mt-6 underline'>Grups</h3>
                {
                  this.state.groups.map((gr, i) =>
                    <div key={`group_${i}`}>
                      <h4>{`Group ${i + 1}`}</h4>
                      <ul className='my-6'>
                        {
                          gr.map((el, j) => <li key={`groups_${j}`}>{el.name}</li>)
                        }
                      </ul>
                    </div>

                  )
                }
              </div>
            )
          }
        </div>
      </Layout>
    )
  }
}

export default Aoe2
